<template>
    <div class="info_box">
        <div class="nav_list">
            <div class="demo-collapse">
                <el-collapse v-model="activeName" accordion  >
                    <el-collapse-item :title="item.name" :name="index"  v-for="(item,index) in infoAll[counter.count]"
                    :key="item.name" :to="{ path: item.url }" @click="toNavContent(item)"
                    @change="active"
                    >
  
                        <router-link v-for="ite in item.child" :key="ite.child" :to="{ path: ite.url, query: { id: ite.id } }"
                            style="display: block; padding: 20px 0 0 40px;">
                            {{ ite.name }}
                        </router-link>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <!-- <router-link v-for="item in infoAll[counter.count]" :key="item.name" :to=" { path:item.url  } "> -->
            <!-- <router-link v-for="item in infoAll[counter.count]" :key="item.name" :to="{ path: item.url }">
                {{ item.name }}
                <router-link v-for="ite in item.child" :key="ite.child" :to="{ path: ite.url, query: { id: ite.id } }"
                    style="display: block; padding: 20px 0 0 40px;">
                    {{ ite.name }}
                </router-link>
            </router-link> -->
        </div>
        <div class="info_show">
            <router-view></router-view>
        </div>






    </div>

</template>
<script>
import { useCounterStore } from '@/store/counter';
export default {
    data() {
        return {
            counter: useCounterStore(),
            activeName :'1',
            infoAll: {
                '0': [
                ],
                '1': [
                    { name: 'Company Milestone', url: '/info/AboutUs/HistoricalDevelopment', id: '1' },
                    { name: 'Company Culture', url: '/info/AboutUs/EnterpriseCulture', id: '1' },
                    { name: 'Certificates & Awards', url: '/info/AboutUs/CertificatesAwards', id: '1' },
                ],
                '2': [
                    { name: 'Business Scope', url: '/info/CustomSolutions/businessSCope', id: '1' },
                ],
                '3': [
                    {
                        name: 'Gas Carrier',
                        url: '/info/ProjectShip/proSynopsis',
                        child: [
                            {
                                name: '9,500m³ Multi-Gas Carrier',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '2',
                            },
                            {
                                name: '5,000m³ LPG Carrier',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '3',
                            },
                            {
                                name: '30,000m³ LNG Carrier & Bunker',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '4',
                            },
                            {
                                name: '180,000m³ LNG Carrier',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '6',
                            },
                            {
                                name: '9,000m³ Multi-Gas Carrier',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '12',
                            },
                            {
                                name: '6,300m³ LPG/NH3 Carrier',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '13',
                            }
                        ]
                    },
                    {
                        name: 'Fuel Gas System',
                        url: '/info/ProjectShip/proSynopsis',
                        child: [
                            {
                                name: '24000DWT Crude Oil Carrier DF Ready',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '1',
                            },
                            {
                                name: '85,000m³ VLGC',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '7',
                            },
                            {
                                name: '84,000m³ VLGC',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '8',
                            },
                            {
                                name: '15,000m³ TSHD',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '9',
                            },
                            {
                                name: '7,000CEU PCTC',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '10',
                            }
                        ]
                    },
                    {
                        name: 'Amnonia', url: '/info/ProjectShip/proSynopsis',
                        child: [
                            // {
                            //     name: '18,000m³ Ammonia Bunker',
                            //     url: '/info/ProjectShip/shipInfoList',
                            //     id: '14',
                            // },
                        ]
                    },
                    // {name: 'CO2', url: '/info/CustomSolutions/shipInfoList',id:'1'},
                    {
                        name: 'Methanol', url: '/info/ProjectShip/proSynopsis', id: '1',
                        child: [
                            {
                                name: '16,000TEU Container Ship',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '11',
                            },
                        ]
                    },
                    {
                        name: 'Technical Services', url: '/info/ProjectShip/proSynopsis', id: '1',
                        child: [
                            {
                                name: '137,000m³ FSRU',
                                url: '/info/ProjectShip/shipInfoList',
                                id: '05',
                            },
                        ]

                    }
                ],
                '4': [
                    { name: 'After-sales Process', url: '/info/ServicesSupport/AfterSalesProcess', id: '1' },
                    { name: 'Maintenance & Repair', url: '/info/ServicesSupport/emergencyResponse', id: '1' },
                    { name: 'ERP', url: '/info/ServicesSupport/PrimaryService', id: '1' },
                ],
                '5': [
                ],
                '6': [
                ],
            },
        }
    },
    methods: {
        toNavContent(i) {
            console.log(i.child)
            if (i.url != '/info/ProjectShip/proSynopsis') {
                this.$router.push(i.url)
            }
        },
        active(){
            this.activeNames = 0
        }
    }
}
</script>
<style scoped>
.collapse .el-collapse-item__header {
    font-size: 18px!important;
}
.nav_list .demo-collapse .el-collapse .el-collapse-item .el-collapse-item__wrap .el-collapse-item__content {
    padding: 0px!important;
}
.info_box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.nav_list {
    width: 19%;
    padding: 10px 10px 25px;
    background-color: #fff;
    border-radius: 5px;
    /* min-height: 600px; */
    /* border: 1px solid #01417e; */
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.nav_list a {
    color: #000;
    font-size: 18px;
}

.nav_list>a {
    display: block;
    padding: 20px 5px 20px;
    border-bottom: 1px solid #999;
    color: #000;
}

.info_show {
    width: 80%;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}

.nav_list a:hover {
    color: rgb(0, 168, 135);
}

/* a {
  font-weight: bold;
  color: red;
} */
@media (max-width: 480px) {
    .info_box {
        flex-direction: column;
    }

    .nav_list {
        width: 100%;
    }

    .nav_list>a {
        padding: 10px 0;
    }

    .info_show {
        width: 100%;
        padding: 0;
    }
}
</style>