<template>
    <div class="word_box">
        <h1>THE WORLD’S LARGEST LNG BUNKER - “HAI YANG SHI YOU 301” RETROFIT COMPLETED</h1>
        <p class="word_data">
            <span>Time: November 18th，2022</span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <!-- <p class="news_tips"><span>2023-10-20</span><span>ShangHai</span></p> -->
        <p>According to recent news from CCTV, the bunkering system retrofit project of “HAI YANG SHI YOU 301” ship undertaken by Guangzhou Wenchong Dockyard Co., Ltd. has been completed and officially put into use on November 15, 2022, Beijing time.</p>
        <p>The delivery marks " HAI YANG SHI YOU 301" has become the world's largest LNG bunker vessel with a total LNG fuel carrying capacity of 30,000 cubic meters and a filling capacity of 1,650 cubic meters per hour. It can berth at domestic coastal ports in China and provides ship-to-ship LNG bunkering services for LNG powered large container ships, dual-fuel bulk carriers, and ro-ro car carriers, realizing simultaneous LNG cargo operation of ship loading and unloading and LNG bunkering.</p>
        <p>Shanghai UPRO Marine System Co., Ltd. (“UPRO” for short) independently completed the basic & detail design of the bunkering system in this project, and was responsible for providing core equipment such as the filling control system, ship-ship link system, and ESD security system. The full technical support and key commissioning work by UPRO ensured the smooth implementation of the project by the shipyard.
        </p>
        <p>It is reported that the ship will start first ship-to-ship bunkering mission in Yantian Port of Shenzhen in January 2023 for the ultra-large LNG-powered container ship under CMA CGM. The amount of single LNG filling is expected to be about 18,000 cubic meters!</p>
        <p>UPRO complies with national policy trends and contributes to the realization of the national transportation green and low-carbon action, to make the marine economy bigger and stronger, and to promote the low-carbon transition of the shipping industry as the actively responding to the "Action Plan for Carbon Peak Before 2030 " which issued by China government.</p>
        <p>UPRO focuses on the application and innovation of low-temperature gas and environmentally friendly energy in the field of Naval Architecture & Marine Engineering. UPRO is in a leading position in China in terms of EPCS general contracting projects of FGSS for LNG/LPG powered ships, CHS & CCS for LPG/LEG/LNG gas carriers. At the same time, UPRO also has rich engineering experience in environmental protection & carbon control technologies such as ammonia fuel & methanol fuel application and carbon capture.  </p>
    
        <img src="@/assets/newsImage/A001.jpg" alt="">
        <P>RETROFIT COMPLETE IN GUANGZHOU WENCHONG DOCKYARD</P>
        <img src="@/assets/newsImage/A002.jpg" alt="">
        <p>“HAI YANG SHI YOU 301” DOCKED AT THE WHARF</p>

    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>