<template>
  <div class="word_box">
    <header>
      <h1 style="text-align: center;">Welcome to Our Corporate Culture</h1>
      <p style="color: #000;font-size: 15px;">Discover the principles that guide us and the impact we strive to make.</p>
    </header>
    <main>
      <section id="mission">
        <h2>Our Mission</h2>
        <p>To empower businesses worldwide with innovative solutions, fostering sustainable growth while enhancing the
          quality of life for our customers, employees, and communities.</p>
      </section>
      <section id="vision">
        <h2>Our Vision</h2>
        <p>To be a global leader in transforming industries through technology, inspiring a future where progress and
          responsibility go hand in hand.</p>
      </section>
      <section id="values">
        <h2>Core Values</h2>
        <ul class="values-list">
          <li>Innovation: We embrace change and continuously seek new ways to improve.</li>
          <li>Integrity: We act ethically, transparently, and with respect for all.</li>
          <li>Collaboration: We believe in the power of teamwork and shared success.</li>
          <li>Sustainability: We are committed to practices that ensure a better tomorrow.</li>
          <li>Customer Focus: We prioritize understanding and exceeding customer expectations.</li>
        </ul>
      </section>
      <section id="csr">
        <h2>Corporate Social Responsibility</h2>
        <p>We recognize our responsibility extends beyond business operations. We actively engage in initiatives that
          promote social welfare and environmental stewardship.</p>
        <h3>Environmental Commitment</h3>
        <p>We strive to minimize our ecological footprint by adopting green technologies, reducing waste, and promoting
          eco-friendly practices within our operations and supply chain.</p>
        <h3>Poverty Alleviation</h3>
        <p>Through partnerships and direct investments, we support projects aimed at poverty alleviation, education, and
          skill development, empowering communities for lasting change.

        </p>
        <router-link @click="cutNwesList(5)" style="display:block; color: rgb(0, 168, 135); font-size: 30px;padding: 5px 0 ;text-align:right;"
          to="/info/new005">
          click to : view our latest news
        </router-link>
        <blockquote class="quote"> "Our actions today shape the world of tomorrow. We are dedicated to making a positive
          difference." </blockquote>
      </section>
    </main>
    <footer>
      <p style="color: #000;">Join us on this journey towards a brighter, more sustainable future.</p>
    </footer>
  </div>
</template>
<script>
import { useCounterStore } from '@/store/counter';
export default {
  methods: {
    cutNwesList(i) {
        const counter = useCounterStore();
        counter.increment(i)
    }
  }
}
</script>
<style scoped>
@import url('@/style/wordType.css')
</style>