<template>
    <div class="word_box">
        <header>
            <h1>UPRO Business Scope</h1>

        </header>
        <!-- 产品分类 -->
        <div>
            <h2>PRODUCT CATEGORY</h2>
            <ul>
                <li>
                    Cargo handling system for gas carriers (LPG/LEG/LNG/NH3...)
                </li>
                <li>
                    LPG/LNG fuel supply system for new-building and retrofitting of dual-fuel vessels

                </li>
                <li>
                Design engineering for Type A/Type B/Type C tanks for gas carriers and dual-fuel powered vessels
                    
                </li>
            </ul>
        </div>
        <div>
            <!-- 服务 -->
            <h2>SERVICE PACKAGE</h2>
            <ul>
                <li>
                    One-stop engineering design and supply & services
                </li>
                <li>
                On-site supervision services during construction
                </li>
                <li>
                Gas trial & Commissioning
                </li>
                <li>
                Crew training
                </li>
                <li>
                After-sales, spare parts, and maintenance & inspection services
                </li>
            </ul>

            <!-- 图片 -->
            <img src="@/assets/business/001.png" alt="" style="width: 100%;">
            <p>Bunkering System / World's Largest LNG Bunkering Vessel</p>

            <div>
                <img style="width: 50%;max-height: 400px;" src="@/assets/business/002.png" alt="">
                <img style="width: 50%;max-height: 400px;" src="@/assets/business/003.png" alt="">
            </div>

            <p>
                The only domestic brand VLGC-LPG supply gas system series engineering case in China currently
            </p>
        </div>

        <div>
            <!-- 货物处理系统 -->
            <h2>
                CARGO HANDLING SYSTEM
            </h2>
            <ul>
                <li>
                Engineering Service For Gas Plant

                </li>
                <li>
                Partial Hull Structure Retroftting Design And Outftting Design (For Retroftting Ship)

                </li>
                <li>
                Project Management

                </li>
                <li>
                Supervising Services At Yard

                </li>
                <li>
                    Gas Trial/Trial Voyage Service
                </li>
            </ul>
            <img src="@/assets/business/005.png" alt="" style="width: 100%;">
            <p>
                Advanced design concepts in the field of marine liquidcargo systems and rich experience in commissioning
            </p>
            <div>
                <img style="width: 50%;max-height: 400px;" src="@/assets/business/006.jpg" alt="">
                <img style="width: 50%;max-height: 400px;" src="@/assets/business/007.jpg" alt="">
            </div>

        </div>

        <div>
            <h2>
                FUEL GAS SUPPLY SYSTEM
            </h2>
            <img src="@/assets/business/008.jpg" style="width: 100%;" alt="">
            <img src="@/assets/business/009.jpg" style="width: 100%;" alt="">
            <img src="@/assets/business/010.jpg" style="width: 100%;" alt="">
            <img src="@/assets/business/011.jpg" style="width: 100%;" alt="">

        </div>
        <div style="background-color: #01417e;">
            <!-- <img src="@/assets/business/004.1.png" style="width: 100%;" alt=""> -->
        </div>
    </div>
</template>
<style scoped>
    @import url('@/style/wordType.css')
</style>