<template>
    <div class="word_box">
        <h1>Shanghai UPRO Marine System Co., Ltd.<br> Successfully Concludes Participation in Marintec China 2023</h1>
        <p class="word_data">
            <span>Time: December 8, 2023</span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <p>
            On December 8, 2023, Marintec China successfully concluded, setting a new record with the participation of
            over 2,000 companies from 15 countries/regions and attracting more than 80,000 professional visitors from
            over 100 countries/regions.
        </p>
        <p>
            During the exhibition, UPRO team received representatives from shipyards and shipowners involved in various
            past and ongoing projects, as well as numerous renowned potential customers from both domestic and
            international markets. The company showcased its comprehensive solutions and innovative technologies,
            further reinforcing its ability to provide full support in addressing the challenges of digitalization and
            decarbonization in the maritime industry.
        </p>
        <h2>
            UPRO Booth Presentations
        </h2>
        <p>
            At their booth, UPRO team presented various innovative solutions for clean fuel gas supplying systems and
            gas carrier cargo-handling systems, shared proprietary knowledge with customers, and provided relevant
            consulting services. These efforts offered professional guidance and new ideas for the sustainable
            development of industry clients.
        </p>
        <h2>
            Appreciation Activities
        </h2>
        <p>
            During the exhibition, UPRO team also held appreciation events for existing customers. These activities
            attracted numerous industry partners, witnessing the latest developments and sustainable development
            commitments of UPRO and its clients.
        </p>
        <p>
            UPRO's successful participation in Marintec China 2023 demonstrated its leading position in the marine
            sector and its steadfast commitment to promoting sustainable development in the industry.
        </p>
        <p>
            Shanghai UPRO Marine System Co., Ltd.. is a company specializing in EPCS (Engineering, Procurement,
            Construction, and Commissioning) projects for gas carrier cargo handling systems and dual-fuel gas supply
            systems in the marine sector. Since its establishment in 2020, UPRO has secured and delivered numerous
            domestic orders in related businesses, consistently upholding the concept of environmental protection to
            develop and contribute more marine new energy products and services.
        </p>
        <img src="@/assets/newsImage/007 (2).jpg" alt="">
        <img src="@/assets/newsImage/007 (3).jpg" alt="">
        <!-- <img src="@/assets/newsImage/007 (10).jpg" alt=""> -->
        <img src="@/assets/newsImage/007 (4).jpg" alt="">
        <img src="@/assets/newsImage/007 (5).jpg" alt="">
        <img src="@/assets/newsImage/007 (6).jpg" alt="">
        <img src="@/assets/newsImage/007 (7).jpg" alt="">
        <img src="@/assets/newsImage/007 (8).jpg" alt="">
        <img src="@/assets/newsImage/007 (9).jpg" alt="">
        <img src="@/assets/newsImage/007 (1).jpg" alt="">
        <img src="@/assets/newsImage/007 (11).jpg" alt="">
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>