<template>
    <div class="word_box">
        <h1>Sea Trials Successful for 85K-VLGC Gas Supply System Retrofit!<br>UPRO Sets Multiple Industry Firsts
        </h1>
        <p class="word_data">
            <span>Time: October 20th,2023</span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <p>
            Recently, Shanghai UPRO Marine Co., Ltd., in close collaboration with Guangzhou COSCO Shipping Heavy
            Industry, successfully completed gas trials and officially delivered the LPG fuel system retrofit project
            for Sinogas's 85K-VLGC series vessels (GAS GEMINI & GAS AQUARIUS).
        </p>
        <img src="@/assets/newsImage/001 (1).png" alt="">
        <p>
            For this 85K series retrofit project, each vessel was outfitted with a new 933m³ LPG deck fuel tank, along
            with high and low-pressure pump skids, water-glycol skids, an ESD system, a ventilation system, a nitrogen
            system, and other auxiliary systems. UPRO's scope of work included comprehensive engineering design,
            equipment supply and installation, and commissioning services.
        </p>
        <img src="@/assets/newsImage/001 (2).png" alt="">
        <p>
            This retrofit project provided significant technical upgrades for the vessels, featuring a short
            implementation period, complex piping systems, and limited commissioning windows. With strong support from
            the shipowner and shipyard, UPRO's project team, leveraging thorough technical preparation, meticulous
            project organization, and extensive on-site experience, overcame numerous challenges to achieve success. The
            project set several industry records, including China's first independently developed LPG gas supply system,
            the shortest shipyard retrofit period, the shortest dock commissioning time slots, and the shortest gas
            trial duration. The system's stable operation, advanced performance, and user-friendly interface earned
            positive recognition from the shipowner and commendation from the engine manufacturer.
        </p>
        <p>
            Low-carbon and environmentally friendly solutions, coupled with green and intelligent technologies, are the
            inevitable development direction of the shipping industry under the global decarbonization trend. Using
            alternative fuels is currently the most direct and effective way to reduce carbon emissions. This retrofit
            enables large gas carriers to more efficiently use its cargo as fuel, significantly reducing fuel costs for
            the shipowner and directly lowering the vessel's carbon emission intensity, thereby aiding in the
            optimization of the fleet's CII/AER ratings.
        </p>
        <p>
            As an emerging gas engineering company in China, Shanghai UPRO Marine System Co., LTD. is dedicated to
            continuously creating value for customers through professional technology and high-quality services.
        </p>
        <img src="@/assets/newsImage/001 (2).jpg" alt="">
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>