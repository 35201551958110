<template>
    <div class="word_box">
        <header>
            <h1>Certificates & Awards</h1>
        </header>
        <h2>The 2023 Shanghai Municipal Science and Technology Innovative Small Giant Enterpris</h2>
        <img src="@/assets/about/005.jpg" alt="">
        <h2>The Certificate of High-Tech Enterprise</h2>
        <img src="@/assets/about/004.jpg" alt="">
        <h2>AIP Certs</h2>
        <div class="img_box">
            <img src="@/assets/about/002.jpg" alt="">
            <img src="@/assets/about/003.jpg" alt="">
        </div>
        <h2>Letter Of Thanks</h2>
        <img src="@/assets/about/006.png" alt="">
    </div>
</template>
<style scoped> 
h2{
    text-align: center;
}
    img {
        display:block;
        padding-bottom: 50px;
        width: 60%;
        margin: 0 auto;
    }
    .img_box {
        width: 60%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 450px) {
        img {
            width: 100%;
        }
        .img_box {
            width: 100%;
        }
    }
</style>
<style scoped>
    @import url('@/style/wordType.css')
</style>