<template>
    <div class="word_box">
        <h1>
            Methanol as Marine Fuel: A Year in Review with 82 Ships Expected by 2028
        </h1>
        <p class="word_data">
            <span>Time: January 03th,2023</span><span>Author: Reprint - Jasmina Ovcina Mandra</span>
        </p>
        <img src="@/assets/newsImage/D001.png" alt="">
        <p>
            2022 marked a triumphant year for methanol as a maritime alternative fuel, as shipowners sought solutions to
            decarbonize their fleets. According to DNV, by December 31, 2022, there were 82 methanol-powered ships in
            operation and on order.
        </p>
        <p>
            This figure represents a substantial increase from the numbers reported in August, with an additional 20
            orders placed.
            The surge in demand is attributed to the benefits of methanol as a fuel choice, including its density that
            does not compromise vessel capacity, relative safety as a fuel, and price attractiveness. Furthermore,
            methanol is biodegradable, which is an added advantage in case of fuel spills.
        </p>
        <p>
            Grey methanol has been identified as a viable solution, but for full decarbonization, shipowners will have
            to shift towards green methanol, the supply of which remains a significant challenge.
        </p>
        <p>
            Of the total methanol-powered vessels, the container shipping sector leads with 47 orders. Nearly half of
            these are from industry giant Maersk, which announced the construction of a 16,000 TEU methanol-powered
            container vessel.
        </p>
        <P>
            Maersk has ordered 19 methanol-powered container ships from Hyundai Heavy Industries and Hyundai Mipo
            Dockyard. This includes six vessels with a nominal capacity of 17,000 TEU ordered in October, 12 16,000 TEU
            vessels, and a feeder ship ordered in 2021.
        </P>
        <P>
            COSCO Shipping Holdings upped the ante in October 2022 by ordering 12 methanol dual-fuel container ships of
            24,000 TEU capacity. The construction contract, signed by the company's subsidiaries OOCL and COSCO Shipping
            Container Lines with NACKS and DACKS, schedules deliveries between 2026 and 2028.
        </P>
        <P>
            Each newbuild, costing $239.8 million, is set for delivery.
        </P>
        <P>
            Another major player, CMA CGM, joined the movement by ordering six 15,000 TEU dual-fuel methanol-powered
            container ships, planned for delivery by end-2025.
        </P>
        <P>
            Some shipowners have also invested in methanol vessels, such as Ocean Yield, which purchased three 5,500 TEU
            methanol ships under construction at Hyundai Heavy Industries in Korea.
        </P>
        <P>
            2023 is expected to witness another wave of orders, as some companies have already announced fleet renewal
            plans involving methanol. HMM, for instance, has invited bids from four Korean shipyards—HHI, SHI, DSME, and
            HJ—for up to nine 8,000 TEU methanol-fueled vessels, due for delivery between 2025 and 2026.
        </P>
        <h2>
            Tankers

        </h2>
        <P>Oil and chemical tankers are following suit, with 20 in operation and another 5 on order, according to DNV's
            Alternative Fuels Insight.

        </P>
        <P>Proman Stena Bulk, a joint venture between tanker firm Stena Bulk and methanol producer Proman, received four
            methanol-fueled tankers this year, with the latest being Stena Prosperous. They have six vessels under
            construction at Guangzhou Shipyard International.

        </P>
        <P>
            In late 2020, Methanex subsidiary Waterfront Shipping ordered eight additional methanol-fueled ships for
            delivery between 2021 and 2023. These deliveries will comprise 60% of the company's 30-vessel fleet powered
            by low-emission methanol dual-fuel technology.
        </P>

        <P>
            In early December 2022, Canada’s Waterfront Shipping inked a time charter agreement with Trafigura for a
            methanol dual-fuel tanker. Trafigura will operate Mari Innovator, co-owned by Waterfront and Clean Sea, as
            part of its global vessel network for clean product trades. Delivered to Waterfront in October 2021, it's
            the fifth of eight methanol dual-fuel tankers ordered from Hyundai Mipo Dockyard.
        </P>
        <h2>
            Bulk Carriers
        </h2>
        <P>
            The bulk shipping sector is less active in adopting alternative fuels, primarily taking initial steps with
            liquefied natural gas. However, Hong Kong-based dry bulk shipping company Pacific Basin selected green
            methanol as the optimal fuel for its first generation of zero-emission vessels. Despite this, the company
            has yet to present detailed designs or proceed with specific orders.
        </P>
        <P>
            Smaller dry bulkers saw breakthroughs with the recent delivery of the first of six diesel-electric dry
            bulkers to Dutch offshore firm Vertom Group. Designed with future conversion to methanol or hydrogen
            electric propulsion in mind.
        </P>
        <P>
            Ship designers are developing various designs to fill the gap, yet the dry bulk industry appears to lag
            behind its peers. Notably, an 85,000 dwt methanol dual-fuel dry bulker designed by Huangpu Wenchong Shipping
            under CSSC recently received AiP from China Classification Society.
        </P>
        <P>
            Market reports indicate mining giant Vale is preparing to order methanol-powered ore carriers. The Brazilian
            miner is reportedly soliciting tenders from shipowners for the next generation of Guaibamax vessels.
        </P>
        <P>
            In July 2022, Vale received in-principle approval from DNV for a multi-fuel box design for iron ore
            carriers, a project seeking low-carbon alternative fuels for transportation.
        </P>
        <P>Roll-on/Roll-off (RoRo) Vessels
        </P>
        <P>
            Clarksons data shows around 93% of new RoRo capacity ordered by shipowners is for LNG-fueled vessels, with
            23% of ships featuring "ammonia/methanol readiness." This excludes the recent announcement of up to six
            dual-fuel methanol-powered Pure Car/Truck Carriers (PCTCs) under a Letter of Intent (LoI) signed by CMES
            with CIMC.
        </P>
        <P>
            New Zealand's logistics specialist Move Logistics Group is also striving to procure a new methanol-ready
            RoRo vessel. The newbuild is anticipated to enter service in November 2023.
        </P>
        <h2>
            Tugs
        </h2>
        <P>
            Per DNV, one cruise ship, one tugboat, and four offshore vessels running on methanol are on order. With all
            vessels' delivery dates considered, the global methanol-powered fleet is projected to reach 82 vessels by
            2028.
        </P>
        <P>
            A notable project underway involves converting a tugboat to run on methanol. Destined for the Port of
            Antwerp-Bruges, the project is described as a first of its kind.
        </P>
        <P>
            Post-installation and testing, Hydrotug 1 aims to fully operate in Antwerp in Q1 2023.
        </P>
        <P>
            Dutch shipbuilder Damen is eyeing methanol-powered tugs, hoping to mass-produce such vessels by 2026.
            Additionally, Turkish shipyard and tug operator Uzmar Shipyard, in collaboration with Canadian naval
            architecture firm Robert Allan, are designing and building a series of new methanol-fueled tugs.
        </P>
        <h2>
            Bunker Vessels

        </h2>
        <P>
            Lastly, in methanol bunkering, Swedish firms Stena Oil, Stena Teknik, and OljOla have formed a joint venture
            to construct a fuel supply vessel designed to transport chemicals, including methanol and various biofuels,
            under IMO II.
        </P>
        <P>
            The new 2,000 dwt oil and chemical tanker, designed by Kuzey, is under construction at GENKA Shipyard in
            Tuzla, Turkey.
        </P>
        <P>
            Another bunker vessel is under construction in Asia. Specifically, Singapore-based fuel supplier Global
            Energy Trading and Stellar Ship Management Services have agreed to build a methanol bunkering vessel.
        </P>
        <P>
            The ship, to be constructed by Sasaki Shipbuilding Co. in Hiroshima, Japan, is planned to join GET's fleet
            by end-2023, becoming Singapore's first A-class bunker vessel for transporting biofuels and methanol.
        </P>

        <p>
            Remarks: Initial article by Jasmina Ovcina Mandra presented on "offshore-energy.biz";Cover image from "METHANOL INSTITUTE".
        </p>
        <p>
            Declaration: If the original author or website proposed the need to delete the public article from this article , please contact Mr. Kevin Y. GE via this mail address: kevin.ge@uxgas.com
        </p>
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>