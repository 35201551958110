<template>
    <div class="word_box">
        <h1>Shanghai UPRO Marine System Co., Ltd.
            <br>Receives AIP Certificate from RINA for the LPG Ammonia Carrier Cargo System Design
        </h1>
        <p class="word_data">
            <span>Time: December 14, 2023</span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <img src="@/assets/newsImage/C001.jpg" alt="">
        <p>
            Shanghai - On December 14, 2023, Shanghai UPRO Marine System Co., Ltd. was honored to receive the Approval
            in Principle (AIP) certificate for the LPG ammonia carrier cargo system design from RINA at RINA's Shanghai
            office. </p>
        <p>
            At the award ceremony, Mr. Luigi Mattera, Operations Director of RINA Greater China, personally presented
            this significant certificate to Mr. Rui Xiaosong, Technical Director of UPRO. </p>
        <p>
            Receiving the AIP certificate marks a significant breakthrough for UPRO in the field of LPG/ammonia carrier
            cargo system design and reflects RINA's high recognition of UPRO's technical strength and innovative
            capabilities. This achievement will further promote UPRO's development and internationalization in the
            industry. </p>
        <p>
            Shanghai UPRO Marine System Co., Ltd. will continue to uphold the principles of technological innovation and
            quality excellence, constantly improving product and service levels to provide customers with superior
            marine cargo handling system and gas supply system solutions. </p>
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>