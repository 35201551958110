import { createApp } from 'vue'
import { createPinia } from 'pinia';
import lazyLoad from '@/store/lazy-load.js';

import App from './App.vue'
// 主路由
import router from './router/router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import BaiduMap from 'vue-baidu-map-3x'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

// 创建 Pinia 实例
const pinia = createPinia();
// 将 Pinia 挂载到 Vue 应用程序
app.use(pinia);
app.use(ElementPlus)
app.use(router)
app.use(BaiduMap, {
    ak: '百度地图ak',
    // v:'2.0',  // 默认使用3.0
    // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
})

// 注册全局自定义指令
app.directive('lazy', lazyLoad);

app.mount('#app')
