<template>
    <div class="nav_head_info" >
        <div >
            <span>SHANGHAI UPRO MARINE SYSTEM CO., LTD.</span>
            <!-- <span> 7 X 24 </span> -->
        </div>
    </div>
    <div class="box_head">
        <div class="logo_box">
            <img src="@/assets/logo.png" alt="">
            
            <el-icon class="iphone_nav_icon" style="color: #01417e;" @click="navShow()"><Grid /></el-icon>
        </div>
        <div class="box_head_content PC_nav">
            <div class="box_nav">
                <router-link v-for="(item,index) in navList" :key="item.name" :to=" { path:item.url }" @click="cutNwesList(index)">
                    {{item.name}}
                </router-link>
            </div>
        </div>
        <div class="box_head_content" :style="{display: navType}">
            <div class="box_nav">
                <router-link v-for="(item,index) in navList" :key="item.name" :to=" { path:item.url }" @click="cutNwesList(index)">
                    {{item.name}}
                </router-link>
            </div>
        </div>
        <div class="box_log">
            
        </div>

    </div>
</template>
<script>
import { useCounterStore } from '@/store/counter';
    export default {
        data() {
            return {
                navType: 'none',
                navList: [
                    {name: 'HOME', url: '/',id:0},
                    {name: 'ABOUT US', url: '/info/AboutUs/HistoricalDevelopment',id:1},
                    {name: 'BUSINESS', url: '/info/CustomSolutions/businessSCope',id:2},
                    {name: 'SHOWCASE', url: '/info/ProjectShip/proSynopsis',id:3},
                    {name: 'SUPPORT', url: '/info/ServicesSupport/AfterSalesProcess',id:4}, 
                    {name: 'NEWS', url: '/info/NewsEvents',id:5},
                    {name: 'CONTACT US', url: '/info/ContactUs',id:6 }
                ],
                // navList: [
                //     {name: 'HOME PAGE', url: '/',id:0},
                //     {name: 'ABOUT US', url: '/info/AboutUs/HistoricalDevelopment',id:1},
                //     {name: 'BUSINESS', url: '/info/CustomSolutions/businessSCope',id:2},
                //     {name: 'PROJECT SHOWCASE', url: '/info/ProjectShip/proSynopsis',id:3},
                //     {name: 'SUPPORT', url: '/info/ServicesSupport/PrimaryService',id:4}, 
                //     {name: 'NEWS & EVENTS', url: '/info/NewsEvents',id:5},
                //     {name: 'CONTACT US', url: '/info/ContactUs',id:6 }
                // ],
            }
        },
        methods: {
            cutNwesList(i) {
                const counter = useCounterStore();
                counter.increment(i)
                this.navType = 'none'
            },
            navShow() {
                if(this.navType == 'none') {
                    this.navType = 'flex'
                }else {
                    this.navType = 'none'
                }
            }
        }
    }
</script>
<style scoped>
.nav_head_info {
    width: 100%;background-color: #01417e;display: flex;justify-content: center;align-items: center;height: 50px;
}
.nav_head_info div {
    width: 100%;display: flex;justify-content: space-between;color: #fff;padding: 0px 80px;font-size: 14px;
}
el-icon {
    color:rgb(0, 168, 135);
}
.box_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: #fff;
    border-bottom: 1px solid #01417e;
    height: 120px
}
.logo_box {
    width: 23%;
    display: flex;
    justify-content: center;
}
.box_head_content {
    width: 77%;
    display: block;
    /* background-color: #01417e; */
}
.box_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    text-align: center;
    /* width: 99.4%; */
}
.box_nav a{
    width: 14%;
    justify-content: center;
    max-height: 80px;
    line-height: 80px;
    font-size: 18px;
    color: #000;
    border-top: 1px solid rgb(0, 168, 135);
    border-bottom: 1px solid rgb(0, 168, 135);
}
.us_info {
    height: 140px;
    display: flex;
    align-items: center;
    background-color: #fff;
}
.logo_box img{
    max-height: 90px;
}
.box_nav ul ,.us_info ul{
    display: flex;
}
.box_nav a{
    display: flex;
    color: #000;
    font-weight: bold;
}
.box_nav a:hover{
    background-color: #00A887;
    color: #fff;
}
.us_info ul li{
    font-size: 14px;
    padding: 0 15px;
    border-right: 1px solid #000;
}
.us_info ul li :hover{
    cursor: pointer;
    border-bottom: 1px solid #000;
}
.upro_log img {
    width: 180px;
    height: 70px;
    background-repeat: no-repeat; /* 阻止图像平铺 */
    background-position: center; /* 图像居中 */
    background-size: contain; /* 保持图像宽高比例，填充背景区域 */
}
.iphone_nav_icon {
        display: none;
    }
@media (max-width: 480px) {
    .box_head_content {
        width: 100%;
        display: flex;
        /* justify-content: flex-end; */
        flex-direction:row-reverse;
    }
    .box_head {
        display: inline-block;
        justify-content: flex-start;
        flex-direction:column;
        height: 50px;
    }
    .logo_box {
        width: 100%;
        justify-content: space-between;
        padding: 0 5px;
    }
    .box_nav {
        display: flex;
        flex-direction:column;
        /* justify-content: flex-end; */
    }
    .box_nav{
        width: 35%;
    }
    .box_nav a{
        width: 100%;
        font-size: 12px;
        line-height: 30px;
    }
    .iphone_nav_icon {
        font-size: 50px;
        display: inline-block;
    }
    .logo_box {
        height: 50px;
    }
    .logo_box img {
        height: 50px;
        width: auto;
    }
    .nav_head_info div{
        padding: 0;
        font-size: 10px;
        display: block;
    }
    .nav_head_info div span {
        display: block;
        padding: 2px 0;
    }
    .logo_box {
        width: 100%;
    }
    .PC_nav {display: none;}
}   
</style>