<template>
    <div class="word_box">
        <h1>UPRO Emergency Response Procedure</h1>
        <h2>1. Overview of Emergency Response Plan</h2>
        <h2>2. Emergency Response Team</h2>
        <ul>
            <li>Formation of Emergency Response Team</li>
            <li>Emergency Contacts</li>
        </ul>
        <h2>3. Emergency Response Process</h2>
        <ul>
            <li>Incident Reporting</li>
            <li>Initial Assessment</li>
            <li>On-site Response</li>
            <li>Problem Resolution</li>
        </ul>
        <h2>4. Resource Preparation and Management</h2>
        <ul>
            <li>Emergency Spare Parts Inventory</li>
            <li>Emergency Equipment and Tools</li>
        </ul>
        <h2>5. Communication and Coordination</h2>
        <ul>
            <li>Internal Communication</li>
            <li>External Coordination</li>
        </ul>
        <h2>6. Training and Drills</h2>
        <ul>
            <li>Emergency Response Training</li>
            <li>Emergency Drills</li>
        </ul>
        <h2>7. Post-Incident Analysis and Improvement</h2>
        <ul>
            <li>Incident Review</li>
            <li>Improvement Plan</li>
        </ul>
        <p>By implementing this comprehensive emergency response mechanism, UPRO aims to ensure quick and effective
            handling of issues during emergencies, ensuring the safe and stable operation of CHS and FGSS.</p>

        <!-- <img src="@/assets/Server/003.png" alt=""> -->
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>