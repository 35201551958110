<template>
  <div class="">
    <el-carousel indicator-position="outside" :interval="2500"  height="600">
      <el-carousel-item v-for="item in homeImages" :key="item">
        <div class="image-container">
          <!-- <el-image :src="item.imageUrl" :alt="'item ' + (index + 1)" lazy /> -->
          <img :src="item.imageUrl" :alt="'item ' + (index + 1)">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>

  <div class="Project_Highlights">
    <span style="font-size: 30px; /* 英文黑体风格字体 */
          font-weight: bold; /* 如果默认字体没有黑体风格，使用bold作为替代 */color:#222;">Project Highlights</span>
    <p style="color: #545454;font-size: 14px;padding-top: 18px">
      SHANGHAI UPRO MARINE SYSTEM CO., LTD. is an emerging enterprise specializing in the application technology of
      cryogenic gases in the maritime and offshore engineering sectors.
    </p>
  </div>
  <div class="slider_container">
    <el-carousel :interval="2000" type="card" height="">
      <el-carousel-item v-for="(item, i) in images" :key="i">
        <div class="image-container">
          <img :src="item.imageUrl" :alt="'item ' + (index + 1)">
        <!-- <img :src="item.imageUrl" style="max-height: 100%;width: 100%;" :alt="'item ' + (index + 1)"> -->
        <!-- <span >{{ item.name }}</span> -->
        <router-link class="custom-text" :to="{ path: item.routerUrl, query: { id: item.id } }" @click="cutNwesList(3)">
          To Project : - {{ item.name }}
        </router-link>
        </div>
        <!-- <div class="carousel-item-content"> -->

        <!-- </div> -->
      </el-carousel-item>
    </el-carousel>

  </div>
</template>

<script>
import { useCounterStore } from '@/store/counter';
export default {
  data() {
    return {
      homeImages: [
        {
          imageUrl: require('@/assets/homeImg/01.jpg'),
        },
        {
          imageUrl: require('@/assets/homeImg/07.jpg'),
        },
        {
          imageUrl: require('@/assets/homeImg/09.jpg'),
        },
        {
          imageUrl: require('@/assets/homeImg/04.jpg'),
        },
        {
          imageUrl: require('@/assets/homeImg/02.jpg'),

        }
      ],
      images: [
        {
          imageUrl: require('@/assets/caseListImg/01.01.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '1',
          name: '24000DWT Crude Oil Carrier DF Ready'
        },
        {
          imageUrl: require('@/assets/caseListImg/02.02.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '2',
          name: '9,500m³ Multi-Gas Carrier'
        },
        {
          imageUrl: require('@/assets/caseListImg/03.06.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '3',
          name: '5,000m³ LPG Carrier'
        },
        {
          imageUrl: require('@/assets/caseListImg/04.01.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '4',
          name: '30,000m³ LNG Carrier & Bunker'
        },
        {
          imageUrl: require('@/assets/caseListImg/05.01.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '5',
          name: '137,000m³ FSRU'
        },
        {
          imageUrl: require('@/assets/caseListImg/07.02.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '7',
          name: '85,000m³ VLGC'
        },
        {
          imageUrl: require('@/assets/caseListImg/08.01.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '8',
          name: '84,000m³ VLGC'
        },
        {
          imageUrl: require('@/assets/caseListImg/09.01.jpg'),
          routerUrl: '/info/ProjectShip/shipInfoList',
          id: '9',
          name: '15,000m³ TSHD'
        },
      ]
    }
  },
  methods: {
      cutNwesList(i) {
          const counter = useCounterStore();
          counter.increment(i)
      }
  }
}
</script>
<style scoped>
.el-carousel__container {
  height: 600px;
}
.image-container {
  width: 100%;  /* 或你需要的宽度 */
  height: 100%;  /* 或你需要的高度 */
  overflow: hidden;  /* 防止内容溢出 */
  position: relative;
}
.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;  /* 确保 img 覆盖整个容器 */
    top: 0;
    left: 0;
}


.carousel-item-content {
  position: relative;
}
.custom-text {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #000;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* 添加阴影效果 */
  background-color: rgba(0, 0, 0, 0.5);
  /* 添加底色 */
  color: white;
  /* 文字颜色 */
  padding: 5px;
  /* 可选：添加一些内边距以增加空间感 */
}
/* 大的轮播 */
.big_pmd {
  height: 600px;
}
/* .big_pmd .el-carousel .el-carousel__container{
  height: 600px!important;
} */
/* .big_pmd .el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  margin: 0;
  text-align: center;
} */
/* 小的轮播 */
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  margin: 0;
  text-align: center;
}

.slider_container {
  width: 70%;
  margin: 0 auto;
}
.slider_container>div {
  /* height: 200px; */
}
.slider_container .slider_container {
  overflow: hidden;
  width: 100%;
  /* height: 200px;  */
  /* 请根据需要调整高度 */
}

.slider_container .slider-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 6);
  /* 为了无缝衔接，图片总宽度是自身宽度的6倍 */
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-image {
  flex: 0 0 100%;
  max-width: 100%;
}

.carousel-image img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.carousel-controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 0 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.carousel-controls button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 24px;
  color: #333;
}
.Project_Highlights {
  padding: 90px 0 50px;
  width: 70%;
  margin: 20px auto;
  text-align: center;
}
@media (max-width: 480px) {
  .image-container img {
    height: 100%;
    width: 100%;
  }
  .Project_Highlights {
    padding: 0%;
    width: 100%;
  }
  .slider_container {
    width: 100%;
  }
  .custom-text {
    font-size: 10px;
  }
  .slider_container>div {
    height: 200px;
  }
  .image-container img {
    height: 200px;
    width: 100%;
  }
  .big_pmd {
    height: 200px;
  }
  .big_pmd>div .el-carousel__container {
    height: 200px;
  }
}
</style>