<template>
    <div class="word_box">
        <h1>Maintenance and Upkeep Plan Outline</h1>
        <p>UPRO is committed to ensuring that the CHS and FGSS servicing our customers' gas carriers are always in
            optimal condition, extending equipment lifespan, improving operational efficiency, and reducing faults and
            downtime. Therefore, we provide each customer with a detailed maintenance and upkeep plan, the main contents
            of which are as follows:</p>
        <h2>1. Overview of Maintenance and Upkeep Plan</h2>
        <h2>2. Regular Inspections and Maintenance</h2>
        <ul>
            <li>Daily Inspections</li>
            <li>Monthly Inspections</li>
            <li>Quarterly Inspections</li>
            <li>Annual Inspections</li>
        </ul>
        <h2>3. Preventive Maintenance</h2>
        <ul>
            <li>Preventive Maintenance Strategy</li>
            <li>Preventive Maintenance of Key Components</li>
        </ul>
        <h2>4.Troubleshooting and Repairs</h2>
        <ul>
            <li>Troubleshooting Guide</li>
            <li>Emergency Repair Services</li>
        </ul>
        <h2>5.Technical Support and Training</h2>
        <ul>
            <li>Technical Support Services</li>
            <li>Training Programs</li>
        </ul>
        <h2>6.Maintenance Records and Reporting</h2>
        <ul>
            <li>Maintenance Record Management</li>
            <li>Regular Reporting</li>
        </ul>
        <h2>7. Continuous Improvement</h2>
        <ul>
            <li>Customer Feedback</li>
            <li>Technical Updates</li>
        </ul>
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>