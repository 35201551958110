<template>
    <div class="word_box">
        <h1>Shanghai UPRO Marine System Co., Ltd.<br> Awarded the 2024 Shanghai First Batch "Specialized, Refined,
            Distinctive, and Innovative" Enterprise Title</h1>
        <p class="word_data">
            <span>Time: May 8, 2024</span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <!-- <p class="news_tips"><span>2023-10-20</span><span>ShangHai</span></p> -->
        <p>
            On May 8, 2024, Shanghai UPRO Marine System Co., Ltd. received exciting news! The company has officially
            been awarded the "Shanghai Specialized, Refined, Distinctive, and Innovative SME" plaque by the Shanghai
            Municipal Commission of Economy and Informatization. This is the first time UPRO has received this honor
            since its establishment in 2020, marking significant recognition from the government for the company's
            technological innovation and specialized development in the marine sector.
        </p>
        <img src="@/assets/about/005.jpg" alt="">
        <p>
            Shanghai UPRO Marine System Co., Ltd. specializes in EPCS (Engineering, Procurement, Construction, and
            Commissioning) projects for gas carrier - cargo handling systems and dual-fuel gas supply systems in the
            marine field. Since its inception, UPRO has leveraged its technical strength and innovation capabilities to
            secure and deliver multiple domestic orders, making a positive contribution to the domestic maritime
            environmental protection efforts.
        </p>
        <p>
            "Specialized, Refined, Distinctive, and Innovative" SMEs are characterized by their specialization,
            refinement, distinctiveness, and innovation. Receiving this honor signifies that UPRO's technical level,
            innovation capability, and distinctive services in the gas carrier-cargo handling systems and dual-fuel gas
            supply systems have been fully recognized by the government and the industry. This will further enhance the
            company's brand image, increase trust from customers, partners, and the broader society, and help attract
            more top talents, expand into broader markets, and strengthen exchanges and cooperation with other
            outstanding enterprises, jointly promoting industry progress and development.
        </p>
        <p>
            Since its establishment, Shanghai UPRO Marine System Co., Ltd. has adhered to the environmental protection
            concept, dedicated to developing and contributing more marine new energy products. The company focuses on
            gas carrier-cargo handling systems and dual-fuel gas supply systems, providing comprehensive solutions in
            the marine sector. In the future, UPRO will continue to pursue excellence along the path of "Specialized,
            Refined, Distinctive, and Innovative" development, striving for superior achievements in technological
            innovation, product development, and market expansion, injecting vigorous momentum into the company's
            sustainable, stable, and healthy growth, and contributing UPRO's strength to the construction of green
            shipping and a healthy marine environment.
        </p>
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>