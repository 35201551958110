<template>
    <div class="img_box">
        <img src="" alt="">
    </div>
    <div class="content_box">
        <div class="title_box">
            <span>
                {{ this.uproTitle[counter.count]  }}
            </span>
        </div>
        <div style="width: 100%;">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { useCounterStore } from '@/store/counter';
    export default {
        data() {
            return {
                counter: useCounterStore(),
                uproTitle: [
                    'HOME PAGE',
                    'ABOUT US',
                    'BUSINESS',
                    'SHOWCASE',
                    'SUPPORT',
                    'NEWS',
                    'CONTACT US'
                ],
                imageUrl:[
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ]
            }
        }
    }
</script>
<style scoped>
.Content {
    background-color: #fff;
}
.img_box {
    width: 100%;
    height: 250px;
    line-height: 250px;
    text-align: center;
    background-color: #000;
    background-image: url(@/assets/01.jpg);
    background-position: center center; /* 图片居中 */
        background-repeat: no-repeat; /* 不重复图片 */
        background-size: cover; /* 图片等比例铺满div */
}
.img_box span{
    color: #ffffff;
    font-size: 33px;
    font-weight: 600;
    line-height: 40px;
    position: relative;
    text-transform: uppercase;
}
.content_box {
    width: 90%;
    min-height: 600px;
    margin: 0 auto;
    padding: 10px 0 0 10px;
    margin-top: 15px;
    background-color: #fff;
}
.title_box span {
    text-transform: uppercase;
    border-bottom: 5px solid #00A887;
    margin-bottom: 40px;
    font-size: 36px;
}
@media (max-width: 480px) {
    .content_box{
        width: 100%;
        padding: 0;
    }
}
</style>