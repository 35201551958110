<template>
    <div class="word_box">
        <span style="display: flex;align-items: center;">
            <el-icon aria-setsize="16">
                <ArrowLeft />
            </el-icon>
            View-More
        </span>
        <h1>Salute To Our Team
        </h1>
        <img src="@/assets/shipCase/proSynopsis/001.jpg" alt="">
        <img src="@/assets/shipCase/proSynopsis/002.jpg" alt="">
        <img src="@/assets/shipCase/proSynopsis/003.jpg" alt="">
        <img src="@/assets/shipCase/proSynopsis/004.jpg" alt="">
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>