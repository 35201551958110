<template>
    <div class="word_box" style="margin-top:20px;">
        <div class="container">
            <h1>Notice of Children's Day Charity Event</h1>
            <p>Dear Colleagues,</p>
            <p>In this sunny season, we are about to usher in the joyful Children's Day. This year, we want to celebrate this joyous occasion in a special way. In the past, the company would show appreciation to employees with children by giving them a token of appreciation on this day to express our care and affection. However, this year, we hope to extend this love to the students of Kusala Village Primary School in Piler Township, Akto County, Kizilsu Kirghiz Autonomous Prefecture, Xinjiang, helping these children fulfill their small wishes:</p>
            <ul>
                <li>A well-fitting new outfit to make them feel loved and warm.</li>
                <li>A set of colorful paintbrushes to stimulate their creativity and artistic talent.</li>
                <li>A cute plush toy to accompany them through every joyful or lonely moment.</li>
            </ul>
            <p>Every donation we make, no matter how small, is a precious gift to these children's childhoods. Let us use love to light up their "micro wishes" and give them a more colorful and joyful childhood.</p>
            <p>Hereby, we are launching a company charity event. At the same time, we also want to actively respond to corporate culture and advocate for environmentally friendly, low-carbon donation methods. We encourage everyone to donate unused items from their homes to the children in the pastoral areas. Such donation methods not only reflect our cherishment of resources but also serve as a profound education for the children—letting them understand that every item has value, and every act of reuse is a form of care for the Earth.</p>
            <h3>1. Corporate Contribution:</h3>
            <p>The company will make donations on behalf of all employees, expressing our deep affection for Kusala Village Primary School in Piler Township, Akto County, Kizilsu Kirghiz Autonomous Prefecture, Xinjiang.</p>
            <h3>2. Employees' Charity:</h3>
            <p>We sincerely invite you to donate items from your home according to your ability. As long as the items are in good condition and still usable. We advocate that donations should stem from sincere intentions and practical actions, rather than purchasing new items.</p>
            <p>Below is the current list of urgently needed supplies:</p>
            <ul>
                <li>Items of Love: Dolls, LEGO bricks, or entertaining toys, etc.</li>
                <li>Educational Materials: Books, stationery, etc.</li>
                <li>Daily Necessities: Hand cream, clothes for adults and children, etc.</li>
            </ul>
            <p>We understand that Kusala Village Primary School in Piler Township, Akto County, Kizilsu Kirghiz Autonomous Prefecture, Xinjiang, is located in a pastoral area where the main source of income for villagers is agriculture and animal husbandry. Therefore, after school, children mostly have to help with farming and feeding cattle and sheep. In rural areas, families often have seven or eight children, with meager incomes unable to meet their expenses. Some children have been wearing their older siblings' clothes since childhood, and their lives are financially tight. Our helping hand will have a profound impact on them. We firmly believe that through everyone's efforts, we can create a happier and more fulfilling childhood for these children.</p>
            <p>We hope that friends who are willing to donate supplies can deliver the items to the Administration and Personnel Department before May 27th. We will arrange for them to be sent to Kusala Village Primary School in Piler Township, Akto County, Kizilsu Kirghiz Autonomous Prefecture, Xinjiang, collectively.</p>
            <p>Let us work together to contribute to the bright future of these children and make their childhoods more colorful and enriching!</p>
            <p class="inscribe">SHANGHAI UPRO MARINE SYSTEM CO., LTD.</p>
            <p class="inscribe">May 16, 2024</p>
        </div>


        <div class="img_box">
            <img src="@/assets/newsImage/005.1.png" alt="">
            <img src="@/assets/newsImage/005.2.png" alt="">
            <img src="@/assets/newsImage/005.3.png" alt="">
            <img src="@/assets/newsImage/005.4.png" alt="">
            <!-- <img src="@/assets/newsImage/005.5.png" alt=""> -->
            <img src="@/assets/newsImage/005.6.png" alt="">
            <img src="@/assets/newsImage/005.7.png" alt="">
            <img src="@/assets/newsImage/005.8.png" alt="">
            <img src="@/assets/newsImage/005.9.png" alt="">
            <img src="@/assets/newsImage/005.10.png" alt="">
            <img src="@/assets/newsImage/005.11.png" alt="">
            <img src="@/assets/newsImage/005.12.png" alt="">
            <img src="@/assets/newsImage/005.13.png" alt="">
        <!-- <img src="@/assets/newsImage/005.14.png" alt=""> -->
        </div>

    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>