<template>
    <div class="contactUsBox">
        <div>
            <div class="contactUsBoxLeft"
                style="">
                <div>
                    <input type="text" placeholder="Your Name*" style="width: 100%; height: 40px;border: 1px solid #f4f4f4;
                        display: block;
                        font-size: 16px;
                        height: 55px;
                        margin-bottom: 30px;
                        padding: 0 20px;
                        width: 100%;
                        transition: all 500ms ease;">
                    <input type="text" placeholder="Your Mail*" name="" id="" style="width: 100%; height: 40px;border: 1px solid #f4f4f4;
                        display: block;
                        font-size: 16px;
                        height: 55px;
                        margin-bottom: 30px;
                        padding: 0 20px;
                        width: 100%;
                        transition: all 500ms ease;">
                    <input type="text" placeholder="Your Phone*" name="" id="" style="width: 100%; height: 40px;border: 1px solid #f4f4f4;
                        display: block;
                        font-size: 16px;
                        height: 55px;
                        margin-bottom: 30px;
                        padding: 0 20px;
                        width: 100%;
                        transition: all 500ms ease;">
                    <textarea type="text" placeholder="Your Message.." required aria-required="true" aria-invalid="true"
                        style="width: 100%;height: 80px;margin-top: 10px;border: 1px solid #f4f4f4;
                        display: block;
                        font-size: 16px;
                        height: 55px;
                        margin-bottom: 30px;
                        padding: 0 20px;
                        width: 100%;
                        transition: all 500ms ease;"></textarea>
                    <p style="color: #999;font-size: 10px;margin-bottom: 30px">*Your information will be used solely for
                        the purpose of collecting your feedback and will not be utilized for any commercial purposes.

                    </p>
                    <button type="submit" class="button_hover" style="background:#222 none repeat scroll 0 0;color: #fff; width: 50%;padding: 15px 0;
                    border-radius: 5px; ">
                        SEND MESSAGE</button>
                </div>
            </div>
            <div class="contactUsBoxRight">
                <p style="font-size: 20px;font-weight: 500;">
                    SHANGHAI UPRO</p>
                <p style="font-size: 20px;font-weight: 500;padding-top: 15px;">MARINE SYSTEM CO., LTD.</p>
                <p style="font-size: 15px;padding-top: 15px;">Tel : +86 21 2042 2276</p>
                <p style="font-size: 15px;padding-top: 15px;">Tel : +86 21 6044 2331</p>
                <p style="font-size: 15px;padding-top: 15px;">Email : info@uxgas.com</p>
                <p style="font-size: 15px;padding-top: 15px;">Adr : Room 1801,BHC Tower,No. 2218 HuNanRoad,Shanghai,
                    China</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.contactUsBox>div {
    display: flex;
    padding-top: 15px;
}
.contactUsBoxLeft {
    width: 50%;padding-right: 5%;border-radius: 8px;box-shadow: 0 0 10px rgb(0 0 0 / 10%);padding: 20px;margin-right: 30px;
}
.contactUsBoxRight {
    width: 40%;padding: 2%;background: #00295B none repeat scroll 0 0;color: #fff;border-radius: 8px;
}
@media (max-width: 480px) {
    .contactUsBox>div {
        display: block;
    }
    .contactUsBoxLeft {
        width: 100%;
    }
    .contactUsBoxRight {
        display: none;
    }
}
</style>
