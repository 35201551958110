import { createRouter, createWebHistory } from 'vue-router';
import homePage from '@/views/homePage.vue';
import NewsEvents from '@/views/NewsEvents.vue';
import ContactUs from '@/views/ContactUs.vue';
import ContentBox from '@/views/content/ContentBox.vue';
import ContentNavList from '@/components/ContentNavList.vue';
//news
import new001 from '@/views/content/newsType/News-85KVLGC.vue';
import new002 from '@/views/content/newsType/NewsTwo.vue';
import new003 from '@/views/content/newsType/newsV003.vue';
import new004 from '@/views/content/newsType/newsV004.vue';
import new005 from '@/views/content/newsType/newsV005.vue';
import new006 from '@/views/content/newsType/newsV006.vue';
import new007 from '@/views/content/newsType/newsV007.vue';


// import TechnicalSupport from '@/views/content/ServicesSupport/TechnicalSupport.vue';
// import ProjectServe from '@/views/content/ServicesSupport/ProjectServe.vue';
//about
import HistoricalDevelopment from '@/views/content/about/HistoricalDevelopment.vue';
import EnterpriseCulture from '@/views/content/about/EnterpriseCulture.vue';
import CertificatesAwards from '@/views/content/about/CertificatesAwards.vue';
// business
import businessSCope from '@/views/content/business/businessSCope.vue';


// server
import PrimaryService from '@/views/content/ServicesSupport/PrimaryService.vue';
import emergencyResponse from '@/views/content/ServicesSupport/emergencyResponse.vue';
import AfterSalesProcess from '@/views/content/ServicesSupport/AfterSalesProcess.vue';

// import maintainScheme from '@/views/content/ServicesSupport/maintainScheme.vue';

// shipInfo
import shipInfoList from '@/views/content/shipCase/shipInfoList.vue';
import proSynopsis from '@/views/content/shipCase/proSynopsis.vue';

 
// 路由配置
const routes = [
  { 
    path: '/', 
    component: homePage 
  },
  { 
    path: '/info', 
    component: ContentBox,
    children: [
      { 
        path: 'AboutUs', 
        component: ContentNavList,
        children:[
          {
            path: 'HistoricalDevelopment',
            component: HistoricalDevelopment
          },
          {
            path: 'EnterpriseCulture',
            component: EnterpriseCulture
          },
          {
            path: 'CertificatesAwards',
            component: CertificatesAwards
          }
        ]
      },
      { 
        path: 'ProjectShip', 
        component: ContentNavList,
        children:[
          {
            path: 'shipInfoList',
            component: shipInfoList,
            props:true
          },
          {
            path: 'proSynopsis',
            component: proSynopsis,
          }
        ]
      },
      { 
        path: 'CustomSolutions', 
        component: ContentNavList,
        children:[
          {
            path: 'businessSCope', 
            component: businessSCope,
          }
        ]
      },
      { 
        path: 'ServicesSupport', 
        component: ContentNavList,
        children:[
          // {
          //   path: 'TechnicalSupport',
          //   component: TechnicalSupport
          // },
          // {
          //   path: 'ProjectServe',
          //   component: ProjectServe
          // },
          {
            path: 'PrimaryService',
            component: PrimaryService
          },
          {
            path: 'emergencyResponse',
            component: emergencyResponse
          },
          {
            path: 'AfterSalesProcess',
            component: AfterSalesProcess
            
          }
          // {
          //   path: 'maintainScheme',
          //   component: maintainScheme
          // }
        ]
       },
      { 
        path: 'NewsEvents', 
        component: NewsEvents 
      },
      { 
        path: 'ContactUs', 
        component: ContactUs 
      },
      { 
        path: 'new001', 
        component: new001
      },
      {
        path: 'new002', 
        component: new002 
      },
      {
        path: 'new003', 
        component: new003
      },
      {
        path: 'new004', 
        component: new004
      },
      {
        path: 'new005', 
        component: new005
      },
      {
        path: 'new006', 
        component: new006
      },
      {
        path: 'new007', 
        component: new007
      },
      // { 
      //   path: 'TechnicalSupport', 
      //   component: TechnicalSupport 
      // }
    ]
   }
];
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});
 router.afterEach(() => {
  // 页面路由跳转后滚动到顶部
  window.scrollTo({ top: 0, behavior: 'smooth' });
});
export default router;