<template>
    <div class="ship_box" v-for="(items, index) in shipInfo" :key="index" v-show="this.$route.query.id - 1 == index">
        <div class="ship_info">
            <!-- <div class="ship_info_item" v-if="items.a.length > 0"> -->
            <h1 >{{ items.proName }}</h1>
            <div class="ship_info_item" v-if="items.a[1].length > 0">
                <ul>
                    <li style="border-bottom: 1px solid #000;font-weight: bold;">
                        ITEM
                    </li>
                    <li v-for="(item, index2) in items.a1" :key="index2">{{ item }}</li>
                </ul>
                <ul>
                    <li style="border-bottom: 1px solid #000;font-weight: bold;">
                        <span>DESCRIPTION</span>
                    </li>
                    <li v-for="(item, index2) in items.a" :key="index2">{{ item }}</li>
                </ul>
            </div>
            <div class="ship_info_chs" v-if="items.b[1].length > 0">
                <ul>
                    <li style="border-bottom: 1px solid #000;font-weight: bold;">
                        ITEM
                    </li>
                    <li v-for="(item, index2) in items.b1" :key="index2">{{ item }}</li>
                </ul>
                <ul>
                    <li style="border-bottom: 1px solid #000;font-weight: bold;">
                        <span>Cargo Handling System</span>
                    </li>
                    <li v-for="(item, index2) in items.b" :key="index2">{{ item }}</li>
                </ul>
            </div>

        </div>
        <div class="word_box showCaseInfo" >
            <h2>Highlights Of UPRO Tech</h2>
            <ul>
                <li v-for="(item, index2) in items.proHigh" :key="index2">{{ item }}</li>
            </ul>
        </div>

        <div class="ship_img">
            <img v-for="(item, index) in items.c" :src="item" :alt="'Image ' + (index + 1)" :key="index">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            shipInfo: [
                // 1--------------------------
                {
                    proName: '24000DWT Crude Oil Carrier DF Ready',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "AG0010AL/AG0011AL",
                        "Wuchang Shipbuilding",
                        "24000DWT Crude Oil Carrier",
                        "",
                        "CMI Nanjing Tanker",
                        "2023",
                        "CCS",
                        "LNG",
                        "1x 50m³ SW Tank",
                        "",
                        "Complete design of LNG fuel supply system",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [
                        require('@/assets/shipCase/01/01.png'),
                        require('@/assets/shipCase/01/02.png'),
                    ],
                    proHigh: [
                        'In-depth communication and cooperation with shipowners and design institutes to effectively reduce emissions of sulfur compounds, carbon dioxide, and other waste',
                        "More rational division of skid units to improve space utilization and retrofit convenience of the vessel",
                        "Excellent energy-saving design to effectively reduce ship operating costs, and so on."
                    ]
                },
                // 2--------------------------
                {
                    proName: '9,500m³ Multi-Gas Carrier',
                    a1: [
                        "CCS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Cargo Containment System",
                        "H5509",
                        "CSSC HPWC",
                        "9,500m³ Multi-Gas Carrier",
                        "",
                        "WIDESHINE",
                        "2022",
                        "CCS",
                        "LPG/LEG/LNG",
                        "4,5 bar g; 18 bar g",
                        "Type-C; 2x 4,500m³ SW Cargo Tank + 2x 250m³ SW Deck Tank",
                        "",
                        "Complete design & Supply of cargo tanks & deck tanks",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [
                        require('@/assets/shipCase/02/01.jpg'),
                        require('@/assets/shipCase/02/02.jpg'),

                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of semi-refrigerated and semi-pressurized LNG/LEG vessels;',
                        'In-depth research on the physical properties and state control of LNG/ethylene cargo;',
                        'Mature cascade refrigeration cycle design;',
                        'Effective design and simulation software for design verification;',
                        'Short design cycle.'
                    ]
                },
                // 3--------------------------
                {
                    proName: '5,000m³ LPG Carrier',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Cargo Handling System",
                        "H2426/H2430",
                        "CSSC HPWC",
                        "5,000m³ LPG Carrier",
                        "",
                        "WIDESHINE",
                        "2022/2023",
                        "CCS",
                        "Type-C; 2x 2,500m³ SW Tank",
                        "LPG",
                        "17,5 bar g",
                        "",
                        "",
                        "",
                        "Complete design & supply of CHS",
                    ],
                    c: [
                        require('@/assets/shipCase/03/01.jpg'),
                        require('@/assets/shipCase/03/02.jpg'),
                        require('@/assets/shipCase/03/03.jpg'),
                        require('@/assets/shipCase/03/04.jpg'),
                        require('@/assets/shipCase/03/05.jpg'),
                        require('@/assets/shipCase/03/06.jpg'),
                        require('@/assets/shipCase/03/07.jpg'),
                        require('@/assets/shipCase/03/08.jpg'),
                        require('@/assets/shipCase/03/09.jpg'),
                        require('@/assets/shipCase/03/10.jpg'),
                        require('@/assets/shipCase/03/11.jpg'),
                        require('@/assets/shipCase/03/12.jpg'),
                        require('@/assets/shipCase/03/13.jpg'),
                        require('@/assets/shipCase/03/14.jpg'),
                        require('@/assets/shipCase/03/15.jpg'),

                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of CPG transport vessels;',
                        'In-depth research on the physical properties and state control of CPG cargo;',
                        'Mature design concepts for liquid cargo systems;',
                        'Effective design and simulation software for design verification;',
                        'Short design cycle.'
                    ]
                },
                // 4--------------------------
                {
                    proName: '30,000m³ LNG Carrier & Bunker',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "BS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Bunkering System",
                        "/",
                        "CSSC Guangzhou Wenchong Dockyard",
                        "30,000m³ LNG Carrier & Bunker",
                        "",
                        "CNOOC",
                        "2022 (Retrofit)",
                        "CCS",
                        "Type-C; 4x SW Bi-lobe Tank of 30,000m³ in total",
                        "LNG",
                        "4~5 bar g",
                        "",
                        "",
                        "",
                        "Complete design & supply of BS",
                    ],
                    c: [

                        require('@/assets/shipCase/04/01.jpg'),
                        require('@/assets/shipCase/04/02.jpg'),
                        require('@/assets/shipCase/04/03.jpg'),
                        require('@/assets/shipCase/04/04.jpg'),
                        require('@/assets/shipCase/04/05.jpg'),
                        require('@/assets/shipCase/04/06.jpg'),
                        require('@/assets/shipCase/04/07.jpg'),
                        require('@/assets/shipCase/04/08.jpg'),
                        require('@/assets/shipCase/04/09.jpg'),
                        require('@/assets/shipCase/04/10.jpg'),
                        require('@/assets/shipCase/04/11.jpg'),
                        require('@/assets/shipCase/04/12.jpg'),
                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of LNG transport vessels;',
                        'In-depth research on the physical properties and state control of LNG cargo;',
                        'Mature design concepts for liquid cargo and bunkering systems;',
                        'Effective design and simulation software for design verification;',
                        'Short design cycle.'
                    ]
                },
                // 5--------------------------
                {
                    proName: '137,000m³ FSRU',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "GT&OTC",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "137,000m³ FSRU",
                        "Cargo Handling System",
                        "ETYFA PROMETHEAS",
                        "COSCO SH",
                        "137,000m³ FSRU",
                        "Cyprus Vaslikos LNG",
                        "2023 (Retrofit)",
                        "LR",
                        "Type-B MOSS; 5x Tank of 137,000m³ in total",
                        "LNG",
                        "",
                        "",
                        "",
                        "",
                        "Operation manuel & technical consultancy services",
                    ],
                    c: [

                        require('@/assets/shipCase/05/01.png'),
                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of LNG-FSRUs;',
                        'In-depth research on the physical properties and state control of LNG cargo;',
                        'Mature design concepts for regasification modules;',
                        'Effective design and simulation software for design verification;',
                        'Short design cycle.'
                    ]
                },
                // 6--------------------------
                {
                    proName: '180,000m³ LNG Carrier',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Cargo Handling System",
                        "CMHI-282-01/02/03/04",
                        "CMHI Haimen",
                        "180,000m³ LNG Carrier",
                        "",
                        "Celsius Tanker",
                        "2025/2026",
                        "DNV",
                        "Membrane; 4x Tank of 180,000m³ in total",
                        "LNG",
                        "",
                        "",
                        "",
                        "",
                        "Complete design of CHS",
                    ],
                    c: [

                    ],
                    proHigh: [
                        'Rich experience in the design and operation of FGSS (Fuel Gas Supply System);',
                        'Familiarity with dual-fuel users and gas demand;',
                        'Effective design and simulation software for design verification;',
                        'User-friendly human-machine interface and highly integrated automation control system."'
                    ]
                },
                // 7--------------------------
                {
                    proName: '85,000m³ VLGC',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "Gas Gemini/Gas Aquarius",
                        "COSCO Guangdong",
                        "85,000m³ VLGC",
                        "",
                        "WIDESHINE",
                        "2023 (Retrofit)",
                        "LR",
                        "LPG",
                        "Type-C; 1x 900m³ SW Tank",
                        "",
                        "Complete design & supply of LPG tank & fuel supply system",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [

                        require('@/assets/shipCase/07/01.jpg'),
                        require('@/assets/shipCase/07/02.jpg'),
                        require('@/assets/shipCase/07/03.jpg'),
                        require('@/assets/shipCase/07/04.jpg'),
                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of LFSS (LNG Fuel Supply System);',
                        'Familiarity with dual-fuel users and gas demand;',
                        'Effective design and simulation software for design verification;',
                        'User-friendly human-machine interface and highly integrated automation control system."'
                    ]
                },
                // 8--------------------------
                {
                    proName: '84,000m³ VLGC',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "Gas Scorpio/Gas Libra",
                        "COSCO Guangdong",
                        "84,000m³ VLGC",
                        "",
                        "WIDESHINE",
                        "2024 (Retrofit)",
                        "LR",
                        "LPG",
                        "Type-C; 2x 900m³ SW Tank",
                        "",
                        "Complete design & supply of LPG tank & fuel supply system",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [
                        require('@/assets/shipCase/08/01.jpg'),
                        require('@/assets/shipCase/08/02.jpg'),
                        require('@/assets/shipCase/08/03.jpg'),
                        require('@/assets/shipCase/08/04.jpg'),
                        require('@/assets/shipCase/08/07.jpg'),
                        require('@/assets/shipCase/08/08.jpg'),
                        require('@/assets/shipCase/08/09.jpg'),
                    ],
                    proHigh: [
                        'Rich experience in LFSS (LNG Fuel Supply System) design and operation;',
                        'Well-versed in dual-fuel users and their gas demand;',
                        'Effective design and simulation software for design verification;',
                        'User-friendly human-machine interface and highly integrated automation control system."'
                    ]
                },
                // 9--------------------------
                {
                    proName: '15,000m³ TSHD',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "ZPMC1124",
                        "ZPMC Qidong",
                        "15,000m³ TSHD",
                        "",
                        "CHEC Dredging",
                        "2024",
                        "CCS",
                        "LNG",
                        "Type-C; 1x 1,550m³ SW Tank",
                        "",
                        "Complete design & supply of LNG tank & fuel supply system",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [
                        require('@/assets/shipCase/09/01.jpg'),
                        require('@/assets/shipCase/09/02.jpg'),
                        require('@/assets/shipCase/09/03.jpg'),
                        require('@/assets/shipCase/09/04.jpg'),
                        require('@/assets/shipCase/09/05.jpg'),
                        require('@/assets/shipCase/09/06.jpg'),

                    ],
                    proHigh: [
                        'Extensive experience in FGSS (Fuel Gas Supply System) design and operation;',
                        'Well-versed in dual-fuel users and their gas demand;',
                        'Effective design and simulation software for design validation;',
                        'User-friendly human-machine interface, highly integrated automation control system."'
                    ]
                },
                // 10--------------------------
                {
                    proName: '7,000CEU PCTC',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "21110055/56/61/62",
                        "CSSC GSI",
                        "7,000CEU PCTC",
                        "",
                        "H-LINE",
                        "2024",
                        "KR",
                        "LNG",
                        "Type-C; 2x 1,675m³ SW Tank",
                        "",
                        "Complete design of LNG tank & TCS",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [
                    ],
                    proHigh: [
                        'Extensive experience in liquid tank design and construction;',
                        'Effective liquid tank design and simulation software for design validation;',
                        'Short design cycle;',
                        'Abundant foundational data for liquid tank design.'
                    ]
                },
                // 11--------------------------
                {
                    proName: '16,000TEU Container Ship',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "Fuel Gas Supplying System",
                        "N1071/72/73/74",
                        "COSCO Yangzhou",
                        "16,000TEU Container Ship",
                        "",
                        "COSCO Shipping",
                        "2024/2025",
                        "CCS",
                        "Methanol",
                        "N/A",
                        "",
                        "Complete design of MeOH fuel supply system",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                    ],
                    b: [
                        "Cargo Handling System",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    c: [

                    ],
                    proHigh: [
                        'Extensive experience in FGSS (Fuel Gas Supply System) design and operation;',
                        'Familiarity with dual-fuel users and their gas demand;',
                        'Effective design and simulation software for design validation;',
                        'User-friendly human-machine interface, highly integrated automation control system.'
                    ]
                },
                // 12--------------------------
                {
                    proName: '9,000m³ Multi-Gas Carrier',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Cargo Handling System",
                        "N1214",
                        "COSCO Dalian",
                        "9,000m³ Multi-Gas Carrier",
                        "",
                        "COSCO Shipping Investment Dalian",
                        "2025 (On-going)",
                        "CCS",
                        "Type-C; 2x 4,500m³ SW Cargo Tank + 2x 250m³ SW Deck Tank",
                        "LPG/LEG/LNG",
                        "",
                        "",
                        "",
                        "",
                        "Complete design & supply of CHS &CCS",
                    ],
                    c: [

                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of semi-refrigerated and semi-pressurized LNG/LEG vessels;',
                        'In-depth research on the physical properties and state control of LNG/ethylene cargo;',
                        'Mature cascade refrigeration cycle design;',
                        'Effective design and simulation software for design validation;',
                        'Short design cycle.'
                    ]
                },
                // 13--------------------------
                {
                    proName: '6,300m³ LPG/NH3 Carrier',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "CHS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Cargo Handling System",
                        "N1213",
                        "COSCO Dalian",
                        "6,300m³ LPG/NH3 Carrier",
                        "",
                        "COSCO Shipping Investment Dalian",
                        "2026 (On-going)",
                        "CCS",
                        "Type-C; 2x 3,150m³ SW Cargo Tank + 1x 40m³ SW Deck Tank",
                        "LPG/LEG/LNG",
                        "",
                        "",
                        "",
                        "",
                        "Complete design & supply of CHS &CCS",
                    ],
                    c: [

                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of semi-refrigerated and semi-pressurized LPG vessels;',
                        'In-depth research on the physical properties and state control of ammonia cargo;',
                        'Effective design and simulation software for design validation;',
                        'Short design cycle."'
                    ]
                },
                // 14--------------------------
                {
                    proName: '18,000m³ Ammonia Bunker',
                    a1: [
                        "FGSS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Fuel Type",
                        "Fuel Tank Type & Volume",
                        "Engine Type",
                        "UPRO Workscope",
                    ],
                    a: [
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                    ],
                    b1: [
                        "CHS & BS",
                        "Project No./Hull No.",
                        "Shipyard",
                        "Ship Type",
                        "Main Hull Parameters",
                        "Ship Owner",
                        "Year Of Built",
                        "Class",
                        "Tank Type & Volume",
                        "Main Cargoes",
                        "Design Temp. & Pressure",
                        "Manifold",
                        "Compressor Configuration",
                        "Pump Configuration",
                        "UPRO Workscope",
                    ],
                    b: [
                        "Cargo Handling System & Bunkering System",
                        "Non-disclosed",
                        "N/A",
                        "18,000m³ Ammonia Bunker",
                        "/",
                        "N/A",
                        "2025/2026 (On-going)",
                        "ABS",
                        "Type-C; 2x 9,000m³ Tank",
                        "Ammonia (NH3)",
                        "",
                        "",
                        "",
                        "",
                        "Concept design of CHS & BS",
                    ],
                    c: [

                    ],
                    proHigh: [
                        'Extensive experience in the design and operation of bunkering vessels;',
                        'In-depth research on the physical properties and state control of ammonia cargo;',
                        'Effective design and simulation software for design validation;',
                        'Short design cycle."'
                    ]
                },
            ]
        }
    }
}
</script>
<style scoped>
.ship_box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.ship_info h1 {
    font-size: 28px;
    margin: 10px 0 30px 0;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    background-color: #01417e;
    color: #fff;
}

.ship_info>div {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.ship_info ul {
    width: 45%;
    background-color: #f8f9fa;
    border-radius: 5px;
    padding: 5px;
}

.ship_info>div ul li {
    /* padding: 10px 10px 10px 50px; */
    height: 30px;
    /* line-height: 30px; */
    font-size: 18px;
    margin: 10px;
}

.ship_img {
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
    display: block;
}

.ship_img img {
    width: 100%;
}
.showCaseInfo {
    box-shadow:none;
    width: 90%;
    margin: 0 auto;
    padding: 0;
}
@media (max-width: 480px) {
    .ship_box {
        padding: 0px;

    }
    .ship_box h1{
        margin: 0px;
    }
    .ship_info ul {
        width: 50%;
    }
    .ship_info>div ul li{
        font-size: 10px;
        margin: 0px;
        /* line-height: auto; */
    }
    .showCaseInfo {
        width: 100%;
    }
    .ship_img {
        width: 100%;
        height: auto;
    }
}
</style>
<style scoped>
@import url('@/style/wordType.css')
</style>