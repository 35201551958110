export default {
    // 指令的 mounted 钩子函数，在元素插入到 DOM 中时触发
    mounted(el, binding) {
      // 创建一个 IntersectionObserver 实例
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          // 如果图片元素进入视窗
          if (entry.isIntersecting) {
            // 设置图片的 src 属性为指定的图片 URL
            el.src = binding.value;
            // 停止观察该图片元素
            observer.unobserve(el);
          }
        });
      });
  
      // 开始观察图片元素
      observer.observe(el);
    }
  };